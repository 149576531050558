import React, { useState, useEffect } from "react";
import axios from "axios"
import { Container, Row, Col } from "react-bootstrap";
import { Divider, Form, FormInput } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DateRangePicker from 'rsuite/DateRangePicker';

const VoucherEdit = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [aloc, setAloc] = useState('')
    const [tipe, setTipe] = useState('')
    const [date, setDate] = useState([])
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [code, setCode] = useState('')
    const [value, setValue] = useState(0)
    const [percent, setPercent] = useState(0)
    const [trx, setTrx] = useState(0)
    const [idVoucher, setIdVoucher] = useState('')
    const [combine, setCombine] = useState('N')
    const [expose, setExpose] = useState('')
    const [minQty, setMinQty] = useState(1)
    

    const alocation = [
        { key: 'M', text: 'Member', value: 'M' },
        { key: 'C', text: 'Customer', value: 'C' },
    ]

    const type = [
        { key: 'D', text: 'Percent', value: 'D' },
        { key: 'N', text: 'Nominal', value: 'N' },
        { key: 'O', text: 'Ongkir', value: 'O' }
    ]

    const handleCheckExpose = (e) => {
        if (e.checked){
            setExpose('Y')
        }else{
            setExpose('N')
        }
    }

    const handleCheckCombine = (e) => {
        if (e.checked){
            setCombine('Y')
        }else{
            setCombine('N')
        }
    }

    const handleSubmit = () => {
        const param = {
            alocation:aloc,
            tipe:tipe,
            date_range:date,
            name:name,
            description:desc,
            voucher_code:code,
            voucher_value:value,
            voucher_percent: percent,
            minimum_qty: minQty,
            minimum_trx:trx,
            id_voucher:idVoucher,
            is_combine:combine,
            is_expose:expose,
        }
        
        axios({
            method:"POST",
            url:`${global.SRV}api_rudrunner_cms/controler/voucher.php/?index=3`,
            headers:{'Content-Type': 'text/plain'},
            data:param
        })
        .then(res => { console.log(res.data)
            if (res.data.state === 200){
                navigate('/voucher')
            }
        })
    }

    useEffect(() => {
        const data = location.state
        setName(data.name)
        setDesc(data.description)
        setAloc(data.customer_type)
        setTipe(data.type)
        setDate([
            new Date(data.start_date),
            new Date(data.end_date)
        ])
        setPercent(data.percent)
        setCode(data.voucher_code)
        setValue(data.nominal)
        setTrx(data.minimum_trx)
        setIdVoucher(data.id_voucher)
        setMinQty(data.min_qty)
        setExpose(data.show)
    },[location.state])

 
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Edit Voucher</div>
                <div className="text-muted">Edit new voucher</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={8} md={12} sm={12}>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Field className="mb-3">
                            <Form.Select
                                label='Alocation'
                                options={alocation}
                                value={aloc}
                                onChange={(e, { value }) => setAloc(value)}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Select
                                label='Type'
                                options={type}
                                value={tipe}
                                onChange={(e, { value }) => setTipe(value)}
                            />
                        </Form.Field>
                        
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Name" defaultValue={name} onChange={(e, { value }) => setName(value)} />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Description" defaultValue={desc} onChange={(e, { value }) => setDesc(value)} />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="text" label="Voucher Code" defaultValue={code} onChange={(e, { value }) => setCode(value)} />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="number" label="Voucher Fixed" value={value} onChange={(e, { value }) => setValue(value)} />
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <Form.Input type="number" label="Voucher Percent" value={percent} onChange={(e, { value }) => setPercent(value)} />
                        </Form.Field>
                        <Form.Group className="mb-3">
                            <Form.Input type="number" label="Minimum Transaction" value={trx} onChange={(e, { value }) => setTrx(value)} />
                            <FormInput min={1} max={10}  type="number" label="Minimum Order Qty" value={minQty} onChange={(e, {value}) => setMinQty(value)}/>
                        </Form.Group>
                        <Form.Field>
                            <Form.Checkbox label="Expose" defaultChecked={location.state.show === 'N' ? false : true} value={location.state.show === 'N' ? false : true} onChange={(e, data) => handleCheckExpose(data)}/>
                            <Form.Checkbox label="Combinable" defaultChecked={location.state.is_combine === 'N' ? false : true} onChange={(e, data) => handleCheckCombine(data)}/>
                        </Form.Field>
                        <Form.Field className="mb-3">
                            <label>Active Date</label>
                            <DateRangePicker
                                value={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="topStart"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Form.Field className="mb-3 mt-5">
                            <Form.Button content="Update Voucher" color="facebook" />
                        </Form.Field>
                    </Form>
                </Col>
            </Row>
        </Container>

    )
}

export default VoucherEdit