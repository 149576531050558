import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { DateRangePicker, Modal } from "rsuite";
import { Button, Checkbox, Divider, Form, Popup } from "semantic-ui-react";
import { Toast } from "../component/Toast";
import { useNavigate } from "react-router-dom";


export const Roulette = () => {
    const navigate = useNavigate()
    const options = { timeZone: 'Asia/Jakarta' };
    const dateNow = new Date();
    const [idRoulette, setIdRoulette] = useState('')
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [minTrx, setMinTrx] = useState(0)
    const [dataRoulette, setDataRoulette] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [date, setDate] = useState([
        new Date(),
        new Date(dateNow.setDate(dateNow.getDate() + 7))
    ])

    const handleClose = () => (setShowEdit(false))

    const handleChangeState = (state, id) => {
        const param = new URLSearchParams()
        param.append('state', state)
        param.append('id', id)
        console.log(state)
        console.log(id)
        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=8`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: param
        }).
            then(res => {
                if (res.data.state === 200) {
                    getRoulette()
                    Toast.fire({
                        icon: 'success',
                        text: 'Roulette state updated...',
                        position: 'top-right'
                    })
                }
            })
    }

    const handleClickSubmit = () => {
        setIsLoad(true)
        const param = {
            name: name,
            desc: desc,
            min_trx: minTrx,
            start: date[0],
            end: date[1]
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=9`,
            headers: { 'Content-Type': 'application/json' },
            data: param
        }).
            then(res => {
                if (res.data.state === 200) {
                    setIsLoad(false)
                    getRoulette()
                    Toast.fire({
                        icon: 'success',
                        text: 'Success adding new roulette',
                        position: 'top-right',
                    })
                } else {
                    setIsLoad(false)
                    Toast.fire({
                        icon: 'error',
                        text: 'Failed adding new roulette',
                        position: 'top-right',
                    })
                }
            })
    }

    const handleEditRoulette = (data) => {
        setIdRoulette(data.id)
        setName(data.roulette_name)
        setDesc(data.desc)
        setMinTrx(data.minimum_trx)
        setDate([
            new Date(data.start_date),
            new Date(data.end_date)
        ])
        setShowEdit(true)
    }

    const handleUpdate = () => {
        const data = {
            id:idRoulette,
            name:name,
            desc:desc,
            trx:minTrx,
            date: JSON.stringify(date),
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=13`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        }).
        then (res => {
            if (res.data.state === 200){
                Toast.fire({
                    icon:'success',
                    text:"Success update Roulette",
                    position:'top-end'
                })
                getRoulette()
                handleClose()
            } else {
                Toast.fire({
                    icon:'error',
                    text:"Failed update Roulette",
                    position:'top-end'
                })
                handleClose()
            }
        })
    }

    const handleDeleteRoulette = (id) => {
        const param = new URLSearchParams()
        param.append('id', id)

        const msg = window.confirm('Sure to delete this?')
        if (msg) {
            axios({
                method: "POST",
                url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=12`,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: param
            }).
                then(res => {
                    if (res.data.state === 200) {
                        getRoulette()
                        Toast.fire({
                            icon: 'success',
                            text: 'Success delete selcted roulette',
                            position: 'top-right'
                        })
                    } else {
                        Toast.fire({
                            icon: 'error',
                            text: 'Failed delete selcted roulette',
                            position: 'top-right'
                        })
                    }
                })
        }
    }

    const getRoulette = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=4`
        }).
            then(res => {
                if (res.data.state === 200) {
                    setDataRoulette(res.data.data)
                }
            })
    }

    useEffect(() => {
        getRoulette()
    }, [])

    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Roulette</div>
                <div className="text-muted">This voucher list that can be used by customer or member</div>
            </Row>
            <Divider />
            {/* <Modal show={showEdit} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Roulette</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Form widths="equal">
                        <Form.Input label="Roulette Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Input label="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
                        <Form.Input type="number" value={minTrx} label="Minimum Transaction" onChange={(e) => setMinTrx(e.target.value)} />
                        <Form.Field>
                            <label>Date</label>
                            <DateRangePicker
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="auto"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Button content="Update" type="submit" color="facebook" />
                    </Form>
                </Modal.Body>
            </Modal> */}
            <Modal backdrop='static' keyboard={false} open={showEdit} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title className="fs-5">Edit Roulette Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleUpdate}>
                        <Form.Input label="Roulette Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <Form.Input size="50" label="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
                        <Form.Input type="number" value={minTrx} label="Minimum Transaction" onChange={(e) => setMinTrx(e.target.value)} />
                        <Form.Field>
                            <label>Date</label>
                            <DateRangePicker
                                defaultValue={date}
                                format="yyyy-MM-dd HH:mm:ss"
                                appearance="default"
                                size="lg"
                                style={{ width: "100%" }}
                                placement="auto"
                                onOk={setDate}
                            />
                        </Form.Field>
                        <Form.Field className="text-end mt-4">
                            <Button content="Update" type="submit" color="facebook" icon="edit"/>
                        </Form.Field>
                    </Form>
                </Modal.Body>
            </Modal>
            <Row className="d-flex justify-content-center mb-3 mt-3">
            <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-center mb-5">
                    <Form widths="equal" onSubmit={handleClickSubmit}>
                        <Form.Group>
                            <Form.Input label="Roulette Name" onChange={(e) => setName(e.target.value)} />
                            <Form.Input label="Description" onChange={(e) => setDesc(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="number" label="Minimum Transaction" onChange={(e) => setMinTrx(e.target.value)} />
                            <Form.Field>
                                <label>Date</label>
                                <DateRangePicker
                                    defaultValue={date}
                                    format="yyyy-MM-dd HH:mm:ss"
                                    appearance="default"
                                    size="lg"
                                    style={{ width: "100%" }}
                                    placement="topStart"
                                    onOk={setDate}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Button content="Submit" icon="add" type="submit" color="facebook" disabled={isLoad} loading={isLoad} />
                    </Form>
                </Col>
                <Col className="mb-2">
                    <h3>Created Roulette</h3>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-center mb-3">
                    <table className="table" style={{width:'100%'}}>
                        <thead className="bg-light">
                            <tr>
                                <td>#</td>
                                <td>Roulette</td>
                                <td>Description</td>
                                <td>Minimum Trx</td>
                                <td>Date</td>
                                <td>Active</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataRoulette.map((item, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{item.roulette_name}</td>
                                        <td>{item.desc}</td>
                                        <td>{global.IDR.format(item.minimum_trx)}</td>
                                        <td>
                                            <Stack direction="vertical" gap={0}>
                                                <div>{item.start_date}</div>
                                                <div>{item.end_date}</div>
                                            </Stack>
                                        </td>
                                        <td>
                                            <Checkbox
                                                style={{ transform: 'scale(.7)' }}
                                                toggle
                                                defaultChecked={item.is_active === 'Y' ? true : false}
                                                onChange={(e) => handleChangeState(item.is_active, item.id)}
                                            />
                                        </td>
                                        <td>
                                            <Button.Group basic size="tiny">
                                                <Popup
                                                    size="mini"
                                                    content="Roulette Prizes List"
                                                    trigger={<Button icon="box" onClick={() => navigate('/roulette_prize', { state: item })} />}
                                                />
                                                <Popup
                                                    size="mini"
                                                    content="Edit Selected Roulette Event"
                                                    trigger={<Button icon="edit" onClick={() => handleEditRoulette(item)} />}
                                                />
                                                <Popup
                                                    size="mini"
                                                    content="Delete Selected Roulette Event"
                                                    trigger={<Button icon="trash" onClick={() => handleDeleteRoulette(item.id)} />}
                                                />
                                            </Button.Group>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
} 