import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Divider, Button, Form, Select, Popup } from "semantic-ui-react";
import Table from "../component/Table";
import axios from "axios";
import { Toast } from "../component/Toast";

export const RoulettePrize = () => {
    const location = useLocation().state
    const [data, setData] = useState([])
    const [modalAdd, setModalAdd] = useState(false)
    const [products, setProducts] = useState([])
    const [prizeName, setPrizeName] = useState('')
    const [prizeTypeValue, setPrizeTypeValue] = useState('')
    const [alloc, setAlloc] = useState('')
    const [voucherTypeValue, setVoucherTypeValue] = useState('')
    const [voucherValue, setVoucherValue] = useState(0)
    const [idProduct, setIdProduct] = useState('')
    const [loadSubmit, setLoadSubmit] = useState(false)
    const prizeType = [
        {key:'', value:'', text:'Not Selected'},
        {key:'zoonk', value :'zoonk', text : 'Zoonk'},
        {key:'voucher', value :'voucher', text : 'Voucher'},
        {key:'item', value :'item', text : 'Item / Barang'},
    ]
    const allocation = [
        {key:'', value:'', text:'Not Selected'},
        {key:'A', value:'A', text:'All Customer & Member'},
        // {key:'M', value:'M', text:'Member'},
        // {key:'C', value:'C', text:'Customer'},
    ]
    const voucherType = [
        {key:'', value:'', text:'Not Selected'},
        {key:'P' , value:'P', text:'Percent'},
        {key:'N' , value:'N', text:'Nominal'},
        {key:'O' , value:'O', text:'Shipping'},
    ]

    const closeModal = () => (setModalAdd(false))
    const showModal = () => (setModalAdd(true))

    const columns = useMemo(() => [
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Type",
            accessor: "prize_tipe"
        },
        {
            Header: "Voucher Type",
            accessor: "voucher_type"
        },
        {
            Header: "Alloc",
            accessor: "alloc"
        },
        {
            Header: "Article",
            accessor: "id_product"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Barocode",
            accessor: "barocode"
        },
        {
            Header: "Value",
            accessor: "prize_value",
            Cell : ({cell}) => {
                const voucher_type = cell.row.original.voucher_type
                if (voucher_type === 'N'){
                    return global.IDR.format(cell.value)
                }else if (voucher_type === 'P'){
                    return cell.value + '%'
                }
            }
        },
        {
            Header: "Action",
            accessor: null,
            Cell : ({cell}) => (
                <Button.Group size="tiny" basic>
                    <Popup trigger={<Button icon="trash" onClick={() => handleDeletePrize(cell.row.original.id)}/>} content="Delete Selected Prize" size="mini"/>
                </Button.Group>
            )
        }
    ], [])

    const handleChangePrizeType = (e, value) => {
        setPrizeTypeValue(value)
        setAlloc('')
        setVoucherTypeValue('')
        setVoucherValue(0)
    }

    const handleDeletePrize = (id) => {
        const param =new URLSearchParams()
        param.append('id', id)
        const msg = window.confirm('Sure to delete this?')
      
        if (msg){
            axios({
                method:"POST",
                url:`${global.SRV}api_rudrunner_cms/controler/event.php/?index=11`,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                data:param
            }).
            then (res => {
                if (res.data.state === 200){
                    getPrize()
                    Toast.fire({
                        icon:'success',
                        text:'Success remove selected prize',
                        position:'top-right'
                    })
                } else {
                    Toast.fire({
                        icon:'error',
                        text:'Failed remove selected prize',
                        position:'top-right'
                    })
                }
            })
        }
    }

    const handleClickSubmit = () => {
        setLoadSubmit(true)
        const param = {
            id_roulette:location.id,
            prize_name : prizeName,
            prize_type : prizeTypeValue,
            alloc : alloc,
            voucher_type : voucherTypeValue,
            voucher_value : voucherValue,
            id_product : idProduct,
            minimum_trx : location.minimum_trx,
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=10`,
            headers : {
                'Content-Type': 'application/json'
            },
            data:param
        }).
        then(res => {
            console.log(res.data.data)
            if (res.data.state === 200){
                setPrizeName('')
                setPrizeTypeValue('')
                setAlloc('')
                setVoucherTypeValue('')
                setVoucherValue(0)
                setLoadSubmit(false)
                getPrize()
                Toast.fire({
                    icon:'success',
                    text:"Success adding prize",
                    position : 'top-right'
                })
            } else {
                setLoadSubmit(false)
                Toast.fire({
                    icon:'error',
                    text:"Failed adding prize",
                    position : 'top-right'
                })
            }
        })
    }

    const getPrize = async () => {
        await axios({
            method: "GET",
            url: `${global.SRV}api_rudrunner_cms/controler/event.php/?index=5`
        }).
            then(res => {
                if (res.data.state === 200) {
                    setData(res.data.data)
                }
            })
    }

    useEffect(() => {
        const getProduct = async () => {
            await axios.get("https://hoopsdevteam.com/api_web/controler/cms_rr.php/?index=36")
                .then(res => {
                    if (res.data.state === 200) {
                        let data = res.data.data
                        let opt = []

                        data.map((item) => {
                            opt.push(
                                {
                                    key: item.article,
                                    value: item.article,
                                    text: item.article + ' | ' + item.description,
                                    name: item.description,
                                    qty: item.total_qty,
                                    id_brand: item.id_brand,
                                }
                            )
                        })
                        setProducts(opt)
                    }
                })
        }

        getProduct()
        getPrize()

        setPrizeTypeValue('')
    }, [])
   
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Roulette Prize</div>
                <div className="text-muted">This Roulette prizes list</div>
            </Row>
            <Divider />
            <Row className="d-flex justify-content-center mb-3 mt-3">
                <Col xl={6} lg={6} md={6} sm={12} className="d-flex justify-content-start mb-3">
                    <div className="fs-5 fw-bold">{location.roulette_name}</div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} className="d-flex justify-content-end mb-3">
                    <Button content="Add New Prize" icon="add" color="facebook" onClick={showModal} />
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} className="d-flex justify-content-center mb-3">
                    <Table data={data} columns={columns} pageRow={10} />
                </Col>
            </Row>

            <Modal show={modalAdd} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Adding New Prize</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleClickSubmit}>
                        <Form.Input label="Prize Name" value={prizeName} onChange={(e, {value}) => setPrizeName(value)}/>
                        <Form.Select 
                            control={Select}
                            options={prizeType} 
                            value={prizeTypeValue}
                            label="Prize Type"
                            onChange={(e, {value}) => handleChangePrizeType(e, value)}
                        />
                        <div className="mb-3" style={prizeTypeValue == 'voucher' ? {display:'block'} : {display:'none'}}>
                        <Form.Select 
                            label="Allocation"
                            options={allocation}
                            onChange={(e,{value}) => setAlloc(value)}
                        />
                        </div>
                        <div className="mb-3" style={prizeTypeValue == 'voucher' ? {display:'block'} : {display:'none'}}>
                        <Form.Select
                            label="Voucher Type"
                            options={voucherType}
                            onChange={(e, {value}) => setVoucherTypeValue(value)}
                        />
                        </div>
                        <div style={prizeTypeValue == 'voucher' ? {display:'block'} : {display:'none'}}>
                        <Form.Input label="Voucher Value" type="number" value={voucherValue} onChange={(e, {value}) => setVoucherValue(value)}/>
                        </div>
                        <div style={prizeTypeValue == 'item' ? {display:'block'} : {display:'none'}}>
                        <Form.Select
                            required
                            search
                            control={Select}
                            options={products}
                            label={{ children: 'Products' }}
                            onChange={(e, {value}) => setIdProduct(value)}
                        />
                        </div>
                        <Form.Field className="text-end mt-3">
                            <Button content="Save Change" icon='add' color="facebook" loading={loadSubmit}/>
                        </Form.Field>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}